import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import MinLayout from "layout/MinimalLayout";
import {
  BUILDERS,
  DASHBOARD,
  DEVPAGES,
  getPageAccess,
  HOMES,
  QUERY,
  SCHEDULE,
  LOGIN,
  RESET,
  REGISTER,
  CONFIRM,
  WIDGET,
} from "helpers/General";
import { Navigate } from "react-router-dom";
import UpdatePassword from "pages/updatePassword/index";
import Widget from "pages/widget/index";
import Dashboard from "pages/dashboard/index";

// render - dashboard
//const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));

// render - login
const Login = Loadable(lazy(() => import("pages/login")));

// render - register
const Register = Loadable(lazy(() => import("pages/register")));

// render - confirm
const Confirm = Loadable(lazy(() => import("pages/confirm")));

// render - forgot
const Reset = Loadable(lazy(() => import("pages/requestReset")));

// render - forgot
const Await = Loadable(lazy(() => import("pages/awaitConfirm")));

// render - sample page
const SamplePage = Loadable(lazy(() => import("pages/devWidgets/SamplePage")));

// render - utilities
const Typography = Loadable(
  lazy(() => import("pages/components-overview/Typography")),
);
const Color = Loadable(lazy(() => import("pages/components-overview/Color")));
const Shadow = Loadable(lazy(() => import("pages/components-overview/Shadow")));
const Schedule = Loadable(lazy(() => import("pages/schedule")));
const Account = Loadable(lazy(() => import("pages/account")));
const Query = Loadable(lazy(() => import("pages/query")));
const Homes = Loadable(lazy(() => import("pages/homes")));
const Builders = Loadable(lazy(() => import("pages/builders")));

// ==============================|| Login ROUTING ||============================== //

/* Will only allow user to navigate to login page.  Children2 is the second option of paths if the user is authenticated. */
export const LoginOnlyRoutes = {
  path: "/",
  element: <MinLayout />,
  children: [
    {
      path: "login",
      access: getPageAccess(LOGIN),
      element: <Login />,
    },
    {
      path: "reset",
      access: getPageAccess(RESET),
      element: <Reset />,
    },
    {
      path: "awaitConfirm",
      access: getPageAccess(RESET),
      element: <Await />,
    },
    {
      path: "update-password",
      access: getPageAccess(RESET),
      element: <UpdatePassword />,
    },
    {
      path: "register",
      access: getPageAccess(REGISTER),
      element: <Register />,
    },
    {
      path: "confirm",
      access: getPageAccess(CONFIRM),
      element: <Confirm />,
    },
    {
      path: "*",
      element: <Navigate replace to="/login" />,
    },
    {
      path: "",
      element: <Navigate replace to="/login" />,
    },
  ],
  children2: [
    {
      path: "login",
      access: getPageAccess(LOGIN),
      element: <Login />,
    },
    {
      path: "reset",
      access: getPageAccess(RESET),
      element: <Reset />,
    },
    {
      path: "update-password/:userId/:confirm",
      access: getPageAccess(RESET),
      element: <UpdatePassword />,
    },
    {
      path: "register",
      access: getPageAccess(REGISTER),
      element: <Register />,
    },
    {
      path: "confirm/:userId/:confirm",
      access: getPageAccess(CONFIRM),
      element: <Confirm />,
    },
    {
      path: "*",
      element: <Navigate replace to="/login" />,
    },
    {
      path: "",
      element: <Navigate replace to="/login" />,
    },
  ],
};

// ==============================|| MAIN ROUTING ||============================== //

export const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "dashboard",
      access: getPageAccess(DASHBOARD),
      element: <Dashboard />,
    },
    {
      path: "schedule",
      access: getPageAccess(SCHEDULE),
      element: <Schedule />,
    },
    {
      path: "query",
      access: getPageAccess(QUERY),
      element: <Query />,
    },
    {
      path: "homes",
      access: getPageAccess(HOMES),
      element: <Homes />,
    },
    {
      path: "builders",
      access: getPageAccess(BUILDERS),
      element: <Builders />,
    },
    {
      path: "account",
      access: getPageAccess(DEVPAGES),
      element: <Account />,
    },
    {
      path: "color",
      access: getPageAccess(DEVPAGES),
      element: <Color />,
    },
    {
      path: "widgets",
      access: getPageAccess(DEVPAGES),
      element: <SamplePage />,
    },
    {
      path: "shadow",
      access: getPageAccess(DEVPAGES),
      element: <Shadow />,
    },
    {
      path: "style",
      access: getPageAccess(DEVPAGES),
      element: <Typography />,
    },
    {
      path: "widget",
      access: getPageAccess(WIDGET),
      element: <Widget />,
    },
    {
      path: "*",
      access: getPageAccess(LOGIN),
      element: <Navigate replace to="/dashboard" />,
    },
    {
      path: "",
      access: getPageAccess(LOGIN),
      element: <Navigate replace to="/dashboard" />,
    },
  ],
};
