import * as React from "react";

import { Grid, Typography, Card as Card, CardContent, Box, Paper } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import {
  getGoogleCalendarTitle,
  getGoogleCalendarDate,
  getFreshdeskStatus,
  deriveBuilder,
} from "helpers/Service";
import { styled, useTheme } from "@mui/material/styles";
import moment from 'moment';

const Cell = styled(Paper)(({ theme}) => ({
  backgroundColor: "white",
  padding: 10,
  borderRadius: 0,
  flexGrow: 1
}));

const RowTitle = styled(Paper)(({ theme}) => ({
  backgroundColor: theme.palette.secondary.light,
  padding: 10,
  borderRadius: 0,
  flexGrow: 1
}));

export default function HomeDetailsCard(props) {
  let theme = useTheme();

  return (
    <Box
      sx={{ minHeight: "250px", backgroundColor: theme.palette.secondary.light }}
    >
      <Grid container direction="row" spacing={0.2} padding={0.5}>
        <Grid
          item
          container
          direction="row"
          xs={12}
          style={{ marginBottom: "3px" }}
        >
          <Cell>
            <Grid item xs={12}>
              {getGoogleCalendarDate(props)}
            </Grid>
            <Grid item xs={12}>
              {getGoogleCalendarTitle(props)}
            </Grid>
          </Cell>
        </Grid>

        <Grid // BIM Status
          item
          container
          xs={12}
        >
          <Grid item xs={12}>
            <RowTitle>
              <Typography variant="h5" component="div">
                {"BIM Status "}
              </Typography>
            </RowTitle>
          </Grid>

          <Grid item xs={2}>
            <Cell>CAB Checklist</Cell>
          </Grid>
          <Grid item xs={10}>
            <Cell>
              {props.bimRecord?.bimChecklists[1]
                ? props.bimRecord?.bimChecklists[1].status
                : "No Status"}
            </Cell>
          </Grid>

          <Grid item xs={2}>
            <Cell>CLA Checklist</Cell>
          </Grid>
          <Grid item xs={10}>
            <Cell>
              {props.bimRecord?.bimChecklists[2]
                ? props.bimRecord?.bimChecklists[2].status
                : "No Status"}
            </Cell>
          </Grid>
        </Grid>

        <Grid // Copper Status
          item
          container
          xs={12}
        >
          <Grid item xs={12}>
            <RowTitle>
              <Typography variant="h5" component="div">
                {"Copper Status "}
              </Typography>
            </RowTitle>
          </Grid>

          <Grid item xs={2}>
            <Cell>Gas</Cell>
          </Grid>
          <Grid item xs={10}>
            <Cell>
              {props.copperRecords?.gas?.state
                ? props.copperRecords.gas.state.toUpperCase()
                : "No Status"}
            </Cell>
          </Grid>

          <Grid item xs={2}>
            <Cell>Indoor Water</Cell>
          </Grid>
          <Grid item xs={10}>
            <Cell>
              {props.copperRecords?.indoorWater?.state
                ? props.copperRecords.indoorWater.state.toUpperCase()
                : "No Status"}
            </Cell>
          </Grid>
          <Grid item xs={2}>
            <Cell>Outdoor Water</Cell>
          </Grid>
          <Grid item xs={10}>
            <Cell>
              {props.copperRecords?.outdoorWater?.state
                ? props.copperRecords.outdoorWater.state.toUpperCase()
                : "No Status"}
            </Cell>
          </Grid>

          <Grid item xs={2}>
            <Cell>Power Net</Cell>
          </Grid>
          <Grid item xs={10}>
            <Cell>
              {props.copperRecords?.powerNet?.state
                ? props.copperRecords.powerNet.state.toUpperCase()
                : "No Status"}
            </Cell>
          </Grid>
        </Grid>

        <Grid // FreshDesk Details
          item
          container
          xs={12}
        >
          <Grid item xs={12}>
            <RowTitle>
              <Typography variant="h5" component="div">
                {"FreshDesk Details "}
              </Typography>
            </RowTitle>
          </Grid>

          <Grid item xs={2}>
            <Cell>Tickets</Cell>
          </Grid>
          <Grid item xs={10}>
            <Cell>{props.tickets ? props.tickets.length : 0}</Cell>
          </Grid>

          {props.tickets?.length > 0
            ? props.tickets.map((ticket) => {
                return (
                  <Grid container item xs={12}>
                    <Grid item xs={1}>
                      <Cell>ID</Cell>
                    </Grid>
                    <Grid item xs={1}>
                      <Cell>{ticket.id ? ticket.id : "No ID"}</Cell>
                    </Grid>
                    <Grid item xs={1}>
                      <Cell>Date</Cell>
                    </Grid>
                    <Grid item xs={2}>
                      <Cell>
                        {ticket.created_at ? moment(ticket.created_at).format("MM/DD/YYYY") : "No Date"}
                      </Cell>
                    </Grid>
                    <Grid item xs={2}>
                      <Cell>Subject</Cell>
                    </Grid>
                    <Grid item xs={5}>
                      <Cell>
                        {ticket.subject ? ticket.subject : "No Subject"}
                      </Cell>
                    </Grid>
                  </Grid>
                );
              })
            : null}
        </Grid>

        <Grid // Calendar Details
          item
          container
          xs={12}
        >
          <Grid item xs={12}>
            <RowTitle>
              <Typography variant="h5" component="div">
                {"Calendar Details"}
              </Typography>
            </RowTitle>
          </Grid>

          <Grid item xs={2}>
            <Cell>Status</Cell>
          </Grid>
          <Grid item xs={10}>
            <Cell>
              {props.srCalendarEvents && props.srCalendarEvents[0]?.status
                ? props.srCalendarEvents[0].status
                : "No Status"}
            </Cell>
          </Grid>

          <Grid item xs={2}>
            <Cell>Notes</Cell>
          </Grid>
          <Grid item xs={10}>
            <Cell>
              {props.srCalendarEvents && props.srCalendarEvents[0]?.calendarNotes
                ? props.srCalendarEvents[0].calendarNotes
                : "No Notes"}
            </Cell>
          </Grid>
        </Grid>

        <Grid // Builder Details
          item
          container
          xs={12}
        >
          <Grid item xs={12}>
            <RowTitle>
              <Typography variant="h5" component="div">
                {"Builder Details"}
              </Typography>
            </RowTitle>
          </Grid>

          <Grid item xs={2}>
            <Cell>Builder</Cell>
          </Grid>
          <Grid item xs={10}>
            <Cell>
              {deriveBuilder(props)}
            </Cell>
          </Grid>

          <Grid item xs={2}>
            <Cell>Walk Date</Cell>
          </Grid>
          <Grid item xs={10}>
            <Cell>
              {props.homeDirectories && props.homeDirectories[0]?.walkDate
                ? moment(props.homeDirectories[0].walkDate).format("MM/DD/YYYY")
                : "No Date"}
            </Cell>
          </Grid>

        </Grid>

      </Grid>
    </Box>
  );
}
