import React, { useState } from "react";

import { Box, Paper, Tab } from "../../../node_modules/@mui/material/index";
import {
  TabContext,
  TabList,
  TabPanel,
} from "../../../node_modules/@mui/lab/index";
import { useTheme, styled } from "@mui/material/styles";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Cell = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  /* padding: 10,
  borderRadius: 0,*/
  flexGrow: 1,
}));

const TabViewContainer = (props) => {
  let theme = useTheme();

  const { children } = props;
  const filteredChildren = children.filter(Boolean);
  const [currentTab, setCurrentTab] = useState("0");

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <TabContext value={currentTab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <TabList
            value={currentTab}
            onChange={handleChange}
            aria-label="tab view container"
          >
            {filteredChildren.map((tab, index) =>
              tab ? (
                <Tab
                  key={tab.props.label + index}
                  label={tab.props.label}
                  value={"" + index}
                  {...a11yProps(index)}
                />
              ) : null,
            )}
          </TabList>
        </Box>
        {filteredChildren.map((tab, index) =>
          tab ? (
            <TabPanel
              key={tab.props.label + "panel" + index}
              value={"" + index}
            >
              <Cell>{tab}</Cell>
            </TabPanel>
          ) : null,
        )}
      </TabContext>
    </Box>
  );
};

export default TabViewContainer;
