import * as React from "react";

import { Grid, Typography, Card, CardContent, Box, Paper } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import {
  getGoogleCalendarTitle,
  getGoogleCalendarDate,
  getFreshdeskStatus,
  deriveBuilder,
  getGoogleCalendarColorFromEvents,
} from "helpers/Service";
import { styled, useTheme } from "@mui/material/styles";
import moment from "moment";
import TabViewContainer from "components/containers/tabViewContainer";
import BimChecklistTable from "../dashboard/BimChecklistTable";
import CopperRecordCard from "../dashboard/CopperRecordCard";
import HomeHistoryCard from "../dashboard/HomeHistoryCard";
import HomeHistoryTimeline from "components/widgets/HomeHistoryTimeline";

const Cell = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: 10,
  borderRadius: 0,
  flexGrow: 1,
}));

const CellHead = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: 10,
  borderRadius: 0,
  flexGrow: 1,
  fontWeight: "bold",
}));

const RowTitle = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: 10,
  borderRadius: 0,
  flexGrow: 1,
}));

export default function HomeDetailsPopupShell(props) {
  if (!props) {
    return;
  }

  return (
    <Box
      sx={{
        minHeight: "750px",
        // backgroundColor: theme.palette.secondary.light,
        bgcolor: "background.paper",
      }}
    >
      <Grid container direction="row" spacing={0.2} padding={0.5}>
        <Grid
          item
          container
          direction="row"
          xs={12}
          style={{ marginBottom: "3px" }}
        >
          <Cell>
            <Grid item xs={12}>
              {getGoogleCalendarDate(props)}
            </Grid>
            <Grid item sx={{ display: "flex" }} xs={12}>
              {getGoogleCalendarTitle(props)}
              <div style={{ paddingLeft: "1em" }}>
                {getGoogleCalendarColorFromEvents(props.event)}
              </div>
            </Grid>
          </Cell>
        </Grid>
      </Grid>
      <TabViewContainer>
        {props.homeDirectories && (
          <HomeHistoryTimeline label="Full History Timeline" home={props} />
        )}
        {props.homeDirectories && (
          <HomeHistoryCard label="Full History Table" home={props} />
        )}
        {props.bimRecord && (
          <BimChecklistTable
            label="BIM Status"
            bimRecord={props.bimRecord}
            checklistDetails={props.bimChecklistDetails}
          />
        )}
        {props.copperRecords && (
          <CopperRecordCard
            label="Copper Status"
            copperRecords={props.copperRecords}
          />
        )}

        {props.tickets && (
          <Box
            // homes={homes}
            label="FreshDesk Details"
          >
            <Grid // FreshDesk Details
              item
              container
              xs={12}
            >
              <Grid item xs={12}>
                <RowTitle>
                  <Typography variant="h5" component="div">
                    {"FreshDesk Details "}
                  </Typography>
                </RowTitle>
              </Grid>

              <Grid item xs={2}>
                <Cell>Tickets</Cell>
              </Grid>
              <Grid item xs={10}>
                <Cell>{props.tickets ? props.tickets.length : 0}</Cell>
              </Grid>

              {props.tickets?.length > 0
                ? props.tickets.map((ticket) => {
                    return (
                      <Grid container item xs={12}>
                        <Grid item xs={1}>
                          <Cell>ID</Cell>
                        </Grid>
                        <Grid item xs={1}>
                          <Cell>{ticket.id ? ticket.id : "No ID"}</Cell>
                        </Grid>
                        <Grid item xs={1}>
                          <Cell>Date</Cell>
                        </Grid>
                        <Grid item xs={2}>
                          <Cell>
                            {ticket.created_at
                              ? moment(ticket.created_at).format("MM/DD/YYYY")
                              : "No Date"}
                          </Cell>
                        </Grid>
                        <Grid item xs={2}>
                          <Cell>Subject</Cell>
                        </Grid>
                        <Grid item xs={5}>
                          <Cell>
                            {ticket.subject ? ticket.subject : "No Subject"}
                          </Cell>
                        </Grid>
                      </Grid>
                    );
                  })
                : null}
            </Grid>
          </Box>
        )}

        {props.event && (
          <Box label="Calendar Details">
            <Grid // Calendar Details
              item
              container
              xs={12}
            >
              <Grid item xs={12}>
                <RowTitle>
                  <Typography variant="h5" component="div">
                    {"Calendar Details"}
                  </Typography>
                </RowTitle>
              </Grid>

              <Grid item xs={2}>
                <Cell>Status</Cell>
              </Grid>
              <Grid item xs={10}>
                <Cell>
                  {props.event && props.event?.status
                    ? props.event.status
                    : "No Status"}
                </Cell>
              </Grid>

              <Grid item xs={2}>
                <Cell>Notes</Cell>
              </Grid>
              <Grid item xs={10}>
                <Cell>
                  {props.event && props.event?.calendarNotes ? (
                    <div
                      style={{
                        border: "1px solid grey",
                        maxHeight: "600px",
                        overflowY: "auto",
                        padding: "1em",
                        color: "black",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: props.event.calendarNotes,
                      }}
                    />
                  ) : (
                    "No Notes"
                  )}
                </Cell>
              </Grid>
            </Grid>
          </Box>
        )}
        {props.homeDirectories && (
          <Box label="Builder Details">
            <Grid // Builder Details
              item
              container
              xs={12}
            >
              <Grid item xs={12}>
                <RowTitle>
                  <Typography variant="h5" component="div">
                    {"Builder Details"}
                  </Typography>
                </RowTitle>
              </Grid>

              <Grid item xs={2}>
                <Cell>Builder</Cell>
              </Grid>
              <Grid item xs={10}>
                <Cell>{deriveBuilder(props)}</Cell>
              </Grid>

              <Grid item xs={2}>
                <Cell>Walk Date</Cell>
              </Grid>
              <Grid item xs={10}>
                <Cell>
                  {props.homeDirectories && props.homeDirectories[0]?.walkDate
                    ? moment(props.homeDirectories[0].walkDate).format(
                        "MM/DD/YYYY",
                      )
                    : "No Date"}
                </Cell>
              </Grid>
            </Grid>
          </Box>
        )}
      </TabViewContainer>
    </Box>
  );
}
