import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear"; //gas meter disconnected
import FormatColorResetIcon from "@mui/icons-material/FormatColorReset"; //bim CLA closed, indoor water meter disconnected
import InvertColorsOffIcon from "@mui/icons-material/InvertColorsOff"; //outdoor water meter disconneced
import RuleIcon from "@mui/icons-material/Rule";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CheckIcon from "@mui/icons-material/Check";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import WaterIcon from "@mui/icons-material/Water";
import BathroomIcon from "@mui/icons-material/Bathroom"; //Outdoor water meter connected
import WaterDamageIcon from "@mui/icons-material/WaterDamage"; //Indoor water connected
import GasMeterIcon from "@mui/icons-material/GasMeter"; //gas meter connected
import FlashOnIcon from "@mui/icons-material/FlashOn"; //electric meter connected
import FlashOffIcon from "@mui/icons-material/FlashOff"; //electric meter disconnected
import CustomDateRange from "components/date/CustomDateRange";
import moment from "moment";
import {
  POWER_NET,
  GAS,
  WATER_OUTDOOR,
  WATER_INDOOR,
  FRESH_STATUS,
  BIM_STATUS,
  COPPER_ENERGY_TYPES,
} from "constants/list";

const BimChecklistMapping = {
  homeownerlogininformation: "HOL",
  homeownerlogininformationgen2: "HOL",
  gen1togen2retrofitwatermeterinformation: "CLA",
  clawatermeterinformationrev2: "CLA",
  clawatermeterinformationgen2: "CLA",
  cabhomecommissioningchecklistrev2: "CAB",
  cabhomecommissioningchecklistgen2: "CAB",
  cabhomecommissioningchecklistgen3: "CAB",
  cabhomecommissioningchecklistretrofit: "CAB",
  clawatermeterinformationmultifamgen2: "CLA",
  utilitymeterinformationmultifamgen2: "CLA",
  utilitymeterinformation: "CLA",
  homeownerlogininformationgen3: "HOL",
  // {"cabbuilderreadinesschecklistgen3": "BLDR"},
  // {"cabhomeclosingchecklistgen3": "CLOSE"},
};

export const NO_VISIT = "No Visit";
export const HS_BOX_CONFIGURED = "HS Box Configured";
export const HARDWARE_PREPPED_WAITING_ON_BUILDER =
  "Hardware Prepped - Waiting on Builder";
export const BUILDER_ISSUES = "Builder Issues";
export const LT_GREEN_MISSINGPAIR_RACHIO = "Lt GREEN - Missing/Pair Rachio";
export const IN_REVIEW_AXIOS = "In Review (Axios)";
export const CX_PARTIAL_COMPLETE = "CX Partial Complete";
export const NETWORK_ONT_MISSING_WG = "Network, ONT Missing, WG";
export const COPPER_DATA_MISSING_REVISIT = "Copper Data Missing (Revisit)";
export const CX_COMPLETE = "CX Complete";

const SrColorMapping = {
  Graphite: NO_VISIT,
  Banana: HS_BOX_CONFIGURED,
  Tomato: HARDWARE_PREPPED_WAITING_ON_BUILDER,
  Tangerine: BUILDER_ISSUES,
  Sage: LT_GREEN_MISSINGPAIR_RACHIO,
  Basil: LT_GREEN_MISSINGPAIR_RACHIO,
  Peacock: IN_REVIEW_AXIOS,
  Blueberry: CX_PARTIAL_COMPLETE,
  Lavender: NETWORK_ONT_MISSING_WG,
  Flamingo: COPPER_DATA_MISSING_REVISIT,
  Grape: CX_COMPLETE,
};

const _KeyToColor = {};
_KeyToColor[NO_VISIT] = "Graphite";
_KeyToColor[HS_BOX_CONFIGURED] = "Banana";
_KeyToColor[HARDWARE_PREPPED_WAITING_ON_BUILDER] = "Tomato";
_KeyToColor[BUILDER_ISSUES] = "Tangerine";
_KeyToColor[LT_GREEN_MISSINGPAIR_RACHIO] = "Sage";
_KeyToColor[IN_REVIEW_AXIOS] = "Peacock";
_KeyToColor[CX_PARTIAL_COMPLETE] = "Blueberry";
_KeyToColor[NETWORK_ONT_MISSING_WG] = "Lavender";
_KeyToColor[COPPER_DATA_MISSING_REVISIT] = "Flamingo";
_KeyToColor[CX_COMPLETE] = "Grape";

export const KeyToColor = _KeyToColor;

const hexMapping = {
  Graphite: "#616161",
  Banana: "#F6BF26",
  Tomato: "#D50000",
  Tangerine: "#F4511E",
  Sage: "#33B679",
  Basil: "#33B679",
  Peacock: "#039BE5",
  Blueberry: "#3F51B5",
  Lavender: "#7986CB",
  Flamingo: "#E67C73",
  Grape: "#8E24AA",
};

const _KeyToHexColor = {};
_KeyToHexColor[NO_VISIT] = "#616161";
_KeyToHexColor[HS_BOX_CONFIGURED] = "#F6BF26";
_KeyToHexColor[HARDWARE_PREPPED_WAITING_ON_BUILDER] = "#D50000";
_KeyToHexColor[BUILDER_ISSUES] = "#F4511E";
_KeyToHexColor[LT_GREEN_MISSINGPAIR_RACHIO] = "#33B679";
_KeyToHexColor[IN_REVIEW_AXIOS] = "#039BE5";
_KeyToHexColor[CX_PARTIAL_COMPLETE] = "#3F51B5";
_KeyToHexColor[NETWORK_ONT_MISSING_WG] = "#7986CB";
_KeyToHexColor[COPPER_DATA_MISSING_REVISIT] = "#E67C73";
_KeyToHexColor[CX_COMPLETE] = "#8E24AA";

export const KeyToHexColor = _KeyToHexColor;

export const CalendarLedgend = [
  { key: NO_VISIT, color: KeyToHexColor[NO_VISIT] },
  { key: HS_BOX_CONFIGURED, color: KeyToHexColor[HS_BOX_CONFIGURED] },
  {
    key: HARDWARE_PREPPED_WAITING_ON_BUILDER,
    color: KeyToHexColor[HARDWARE_PREPPED_WAITING_ON_BUILDER],
  },
  { key: BUILDER_ISSUES, color: KeyToHexColor[BUILDER_ISSUES] },
  {
    key: LT_GREEN_MISSINGPAIR_RACHIO,
    color: KeyToHexColor[LT_GREEN_MISSINGPAIR_RACHIO],
  },
  { key: IN_REVIEW_AXIOS, color: KeyToHexColor[IN_REVIEW_AXIOS] },

  { key: CX_PARTIAL_COMPLETE, color: KeyToHexColor[CX_PARTIAL_COMPLETE] },
  {
    key: NETWORK_ONT_MISSING_WG,
    color: KeyToHexColor[NETWORK_ONT_MISSING_WG],
  },
  {
    key: COPPER_DATA_MISSING_REVISIT,
    color: KeyToHexColor[COPPER_DATA_MISSING_REVISIT],
  },
  { key: CX_COMPLETE, color: KeyToHexColor[CX_COMPLETE] },
];

const RgbaMapping = {
  Graphite: "rgba(140, 140, 140, 0.5)",
  Banana: "rgba(173, 139, 0, 0.5)",
  Tomato: "rgba(83, 29, 171, 0.5)",
  Tangerine: "rgba(83, 29, 171, 0.5)",
  Sage: "rgba(56, 158, 13, 0.5)",
  Basil: "rgba(56, 158, 13, 0.5)",
  Peacock: "rgba(8, 151, 156, 0.5)",
  Blueberry: "rgba(0, 80, 179, 0.7)",
  Lavender: "rgba(105, 192, 255, 0.5)",
  Flamingo: "rgba(196, 29, 127, 0.5)",
  Grape: "rgba(57, 16, 133, 0.7)",
};

export const defaultDate = () => {
  return [moment().subtract(30, "days").format(), moment().format()];
};

export const getDateRange = (updateRange) => {
  return <CustomDateRange updateRange={updateRange} />;
};

const dateTypeRegex = /(\d{1,4})([\/-])(\d{1,2})\2(\d{1,4})/;

export const getShortSummaryFromAxiosDescription = (axiosDescription) => {
  let split = axiosDescription.split("\n");
  if (split.length <= 0) {
    split = axiosDescription.split("<br>");
  }
  return split[0];
};

export const getShortSummaryFromSrDescription = (srDescription) => {
  let lastNote = null;
  let notesByLine = srDescription;
  if (!srDescription) {
    return;
  }

  if (notesByLine.includes("<br>")) {
    notesByLine = notesByLine.split("<br>");
  }
  if (notesByLine.includes("\n")) {
    notesByLine = notesByLine.split("\n");
  }

  if (notesByLine != null && notesByLine.length > 0) {
    let lastIndex = 1;
    while (!lastNote && lastIndex <= notesByLine.length) {
      let lastNoteSplit = notesByLine.slice(-lastIndex);

      lastNote = lastNoteSplit[0];

      if (lastNote) {
        lastNote = lastNote.replace("&nbsp;", "");
        lastNote = lastNote.replace(/(<([^>]+)>)/gi, "");
        if (!dateTypeRegex.test(lastNote)) {
          //if the lastNote doesn't contain a date then set it to null
          lastNote = null;
        }
      }

      lastIndex += 1;
    }
  }

  if (!lastNote) {
    lastNote = notesByLine[0];
  }
  return lastNote;
};

export const getGoogleCalendarColorFromEvents = (eventToTake) => {
  var color = "Graphite";

  if (eventToTake)
    if (eventToTake.calendarColor) color = eventToTake.calendarColor;

  return (
    <Tooltip title={SrColorMapping[color]}>
      <Box
        sx={{
          width: 25,
          height: 20,
          marginTop: 0.5,
          backgroundColor: hexMapping[color],
          "&:hover": {
            backgroundColor: hexMapping[color],
            opacity: [0.9, 0.8, 0.7],
          },
        }}
      />
    </Tooltip>
  );
};

export const getGoogleCalendarColor = (home) => {
  var color = "Graphite";
  var mapping = SrColorMapping[color];

  if (home.srCalendarEvents)
    if (home.srCalendarEvents.length > 0) {
      var eventToTake = home.srCalendarEvents[0];
      if (eventToTake)
        if (eventToTake.calendarColor) color = eventToTake.calendarColor;
    }

  return (
    <Tooltip title={SrColorMapping[color]}>
      <Box
        sx={{
          width: 25,
          height: 20,
          marginTop: 0.5,
          backgroundColor: hexMapping[color],
          "&:hover": {
            backgroundColor: hexMapping[color],
            opacity: [0.9, 0.8, 0.7],
          },
        }}
      />
    </Tooltip>
  );
};

export const getGoogleCalendarTitle = (home) => {
  //Address to use if there's no google cal address.
  var defaultAddress = home.address;
  if (home.event) {
    defaultAddress = home.event.address;
  } else {
    if (home.srCalendarEvents)
      if (home.srCalendarEvents.length > 0) {
        if (home.srCalendarEvents.length > 1) {
          /*Sometimes mutiple events may exist for a home.  If so, just take the one that was parsed correctly. */
          for (var i = 0; i < home.srCalendarEvents.length; i++) {
            if (home.srCalendarEvents[i].address)
              if (
                home.srCalendarEvents[i].address.length > defaultAddress.length
              )
                defaultAddress = home.srCalendarEvents[i].address;
          }
        } else {
          if (home.srCalendarEvents[0].address)
            if (home.srCalendarEvents[0].address.length > defaultAddress.length)
              defaultAddress = home.srCalendarEvents[0].address;
        }
      }
  }

  return (
    <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
      {" "}
      {defaultAddress}{" "}
    </Typography>
  );
};

export const getGoogleCalendarDate = (home) => {
  //Address to use if there's no google cal address.

  var defaultDate = moment().format("dddd, MMMM Do YYYY");
  if (home.sortingDate) {
    defaultDate = moment(home.sortingDate).format("dddd, MMMM Do YYYY");
  }

  if (
    moment(home.sortingDate).isBefore("1900-12-31", "year") &&
    home.event?.closingTime
  ) {
    defaultDate = moment(home.event.closingTime).format("dddd, MMMM Do YYYY");
  }

  if (defaultDate == null) {
    defaultDate = moment().format("dddd, MMMM Do YYYY");
  }
  // console.log(defaultDate);

  return (
    <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
      {" "}
      {defaultDate}{" "}
    </Typography>
  );
};

export const getBimChecklistStatusText = (home) => {
  var defaultText = "CLA Open CAB Open";
  var textStatus = "";

  if (home)
    if (home.bimRecord)
      if (home.bimRecord.bimChecklists)
        if (home.bimRecord.bimChecklists.length > 0) {
          home.bimRecord.bimChecklists.forEach((checklist) => {
            // console.log("Check list name: ", checklist.name);
            if (checklist.name) {
              var nameStripped = checklist.name
                .replace(" ", "")
                .replace(" ", "")
                .replace(" ", "")
                .replace(" ", "")
                .replace(" ", "")
                .replace(" ", "")
                .replace(" ", "");
              nameStripped = nameStripped.toLowerCase();

              // console.log("Check list name: ", nameStripped);
              //  console.log("Mapping found: ", BimChecklistMapping[nameStripped]);

              if (BimChecklistMapping[nameStripped] == "CLA" || "CAB") {
                var status = checklist.status;

                if (status)
                  textStatus +=
                    " " + BimChecklistMapping[nameStripped] + status;
              }
            }
          });
        }

  return textStatus != "" ? textStatus : defaultText;
};

export const getBimChecklistStatusIcons = (home, checklistCode) => {
  //console.log("Checklist Status Icon: ", home);

  var claComplete = (
    <Tooltip title="CLA Water Report Open or Not Complete">
      <IconButton>
        <FormatColorResetIcon />
      </IconButton>
    </Tooltip>
  );
  var cabCheckComplete = (
    <Tooltip title="CAB Checklist Complete Open or Not Complete">
      <IconButton>
        <RuleIcon />
      </IconButton>
    </Tooltip>
  );

  if (home)
    if (home.bimRecord)
      if (home.bimRecord.bimChecklists)
        if (home.bimRecord.bimChecklists.length > 0) {
          if (checklistCode)
            if (checklistCode == "CLA" || checklistCode == "CAB") {
              home.bimRecord.bimChecklists.forEach((checklist) => {
                // console.log("Check list name: ", checklist.name);

                if (checklist.name) {
                  //Change to regex
                  var nameStripped = checklist.name
                    .replace(" ", "")
                    .replace(" ", "")
                    .replace(" ", "")
                    .replace(" ", "")
                    .replace(" ", "")
                    .replace(" ", "")
                    .replace(" ", "");
                  nameStripped = nameStripped.toLowerCase();

                  // console.log("Check list name: ", nameStripped);
                  //  console.log("Mapping found: ", BimChecklistMapping[nameStripped]);

                  if (BimChecklistMapping[nameStripped] == checklistCode) {
                    var status = checklist.status;

                    if (status) {
                      // console.log("mapping found with status: ", status);
                      if (status == "Closed") {
                        //console.log("mapping found with status closed: ", home);
                        claComplete = (
                          <Tooltip title="CLA Water Report Complete">
                            <IconButton>
                              <WaterIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                        );
                        cabCheckComplete = (
                          <Tooltip title="CAB Checklist Complete">
                            <IconButton>
                              <FactCheckIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                        );
                      }
                    }
                  }
                }
              });
            } else {
              //Other checklist we don't care about yet.
            }
        }

  return checklistCode == "CLA" ? claComplete : cabCheckComplete;
};

export const getCopperStatusIcons = (home, iconType) => {
  //  console.log('energy types', iconType, home );

  var energyIcon = <ClearIcon />;
  var energyStatus = "disconnected";

  if (home) {
    switch (iconType) {
      case POWER_NET:
        if (home.copperRecords)
          if (home.copperRecords.powerNet)
            energyStatus = home.copperRecords.powerNet.state;

        energyIcon =
          energyStatus == "connected" ? (
            <FlashOnIcon color="primary" />
          ) : (
            <FlashOnIcon />
          );
        break;

      case WATER_OUTDOOR:
        if (home.copperRecords)
          if (home.copperRecords.outdoorWater)
            energyStatus = home.copperRecords.outdoorWater.state;

        energyIcon =
          energyStatus == "connected" ? (
            <BathroomIcon color="primary" />
          ) : (
            <BathroomIcon />
          );
        break;

      case WATER_INDOOR:
        if (home.copperRecords)
          if (home.copperRecords.indoorWater)
            energyStatus = home.copperRecords.indoorWater.state;

        energyIcon =
          energyStatus == "connected" ? (
            <WaterDamageIcon color="primary" />
          ) : (
            <WaterDamageIcon />
          );
        break;
      case GAS:
        if (home.copperRecords)
          if (home.copperRecords.gas)
            energyStatus = home.copperRecords.gas.state;

        energyIcon =
          energyStatus == "connected" ? (
            <GasMeterIcon color="primary" />
          ) : (
            <GasMeterIcon />
          );
        break;
      default:
        energyIcon = <ClearIcon />;
    }
  }

  var copperIconButton = (
    <Tooltip title={"Copper " + iconType + " status: " + energyStatus}>
      <IconButton>{energyIcon}</IconButton>
    </Tooltip>
  );

  return copperIconButton;
};

export const getFreshdeskStatus = (home) => {
  //  console.log('Fresh tickets', home );
  var numberOfTickets = "0";

  if (home) if (home.tickets) numberOfTickets = home.tickets.length;

  return numberOfTickets + " Tickets";
};

export const getHomeColor = (home) => {
  //  console.log('Fresh tickets', home );
  var homeColor = "Graphite";

  if (home)
    if (home.srCalendarEvents)
      if (home.srCalendarEvents.length > 0)
        homeColor = home.srCalendarEvents[0].calendarColor;

  return homeColor;
};

export const getCopperEnergyStatus = (home) => {
  var copperStatusList = [];

  if (home)
    if (home.copperRecords) {
      if (home.copperRecords.gas)
        if (home.copperRecords.gas.state)
          if (home.copperRecords.gas.state == "connected")
            copperStatusList.push(COPPER_ENERGY_TYPES[3]);

      if (home.copperRecords.indoorWater)
        if (home.copperRecords.indoorWater.state)
          if (home.copperRecords.indoorWater.state == "connected")
            copperStatusList.push(COPPER_ENERGY_TYPES[2]);

      if (home.copperRecords.outdoorWater)
        if (home.copperRecords.outdoorWater.state)
          if (home.copperRecords.outdoorWater.state == "connected")
            copperStatusList.push(COPPER_ENERGY_TYPES[1]);

      if (home.copperRecords.powerNet)
        if (home.copperRecords.powerNet.state)
          if (home.copperRecords.powerNet.state == "connected")
            copperStatusList.push(COPPER_ENERGY_TYPES[0]);
    }

  return copperStatusList;
};

export const getBimChecklistStatus = (home) => {
  var bimStatusList = [];

  if (home)
    if (home.bimRecord)
      if (home.bimRecord.bimChecklists)
        if (home.bimRecord.bimChecklists.length > 0) {
          home.bimRecord.bimChecklists.forEach((checklist) => {
            //Change to regex
            var nameStripped = checklist.name
              .replace(" ", "")
              .replace(" ", "")
              .replace(" ", "")
              .replace(" ", "")
              .replace(" ", "")
              .replace(" ", "")
              .replace(" ", "");
            nameStripped = nameStripped.toLowerCase();

            // console.log("Check list name: ", nameStripped);
            //  console.log("Mapping found: ", BimChecklistMapping[nameStripped]);

            if (BimChecklistMapping[nameStripped] == "CLA") {
              if (checklist.status == "Open") {
                bimStatusList.push(BIM_STATUS[0]);
              }
            } else if (BimChecklistMapping[nameStripped] == "CAB") {
              if (checklist.status == "Open") {
                bimStatusList.push(BIM_STATUS[1]);
              }
            }
          });
        }

  return bimStatusList;
};

export const getBimChecklist = (checklist) => {
  var bimChecklist = "";

  if (checklist != null) {
    var nameStripped = checklist.name
      .replace(" ", "")
      .replace(" ", "")
      .replace(" ", "")
      .replace(" ", "")
      .replace(" ", "")
      .replace(" ", "")
      .replace(" ", "");
    nameStripped = nameStripped.toLowerCase();

    if (BimChecklistMapping[nameStripped] == "CLA") {
      bimChecklist = "CLA";
    } else if (BimChecklistMapping[nameStripped] == "CAB") {
      bimChecklist = "CAB";
    } else {
      bimChecklist = "HOL";
    }
  }

  return bimChecklist;
};

export const getFreshdeskTicketStatus = (home) => {
  var freshTicketStatus = [];

  if (home)
    if (home.tickets)
      if (home.tickets.length > 0) freshTicketStatus.push(FRESH_STATUS[0]);

  return freshTicketStatus;
};

export const getHomesWithTickets = (homes) => {
  var homesWithTickets = [];

  homes?.map((home, key) => {
    if (home.tickets.length > 0) {
      home.tickets.forEach((ticket) => {
        homesWithTickets.push(home);
      });
    }
  });

  return homesWithTickets;
};

export const getBuilderIssueHomes = (homes) => {
  var builderHomes = [];

  homes?.map((home, key) => {
    if (home.srCalendarEvents[0]?.status?.includes("Builder")) {
      builderHomes.push(home);
    }
  });

  return builderHomes;
};

export const getWeeklySummaryHomes = (homes, dateRangeMinus, dateRangePlus) => {
  const dateRange = [
    moment(dateRangeMinus).valueOf(),
    moment(dateRangePlus).valueOf(),
  ];

  let trimmedHomes = [];
  homes?.map((home, key) => {
    let closingTime;

    if (home.srCalendarEvents[0]) {
      closingTime = moment.utc(home.srCalendarEvents[0].closingTime).valueOf();
    } else if (home.sortingDate) {
      closingTime = moment.utc(home.sortingDate).valueOf();
    }

    if (closingTime > dateRange[0] && closingTime < dateRange[1])
      trimmedHomes.push(home);
  });

  return trimmedHomes;
};

export const getBacklogHomes = (validatedHomes) => {
  var backlogHomes = [];

  validatedHomes?.map((home, key) => {
    if (
      home[0]?.cxComplete == false &&
      moment(home[0].validatedHome?.sortingDate).valueOf() < moment().valueOf()
    ) {
      backlogHomes.push(home);
    }
  });

  return backlogHomes;
};

export const getClaPassedHomes = (validatedHomes, filteredHomes) => {
  var passedHomes = [];

  filteredHomes?.map((home, key) => {
    if (getBimChecklistStatusText(home).includes("CLA Open")) {
      let errorsToCheck = validatedHomes.filter(
        (x) => x[2]?.validatedHome?.address == home.address,
      );

      if (errorsToCheck[2]?.message.includes("success"))
        passedHomes.push(errorsToCheck);
    }
  });

  return passedHomes;
};

export const getClaFailedHomes = (validatedHomes, filteredHomes) => {
  var failedHomes = [];

  filteredHomes?.map((home, key) => {
    if (getBimChecklistStatusText(home).includes(BIM_STATUS[0])) {
      let errorsToCheck = validatedHomes.filter(
        (x) => x[0].validatedHome.address == home.address,
      );

      //if(!errorsToCheck[2]?.message.includes("success"))
      // passedHomes.push(errorsToCheck);
    }
  });

  return failedHomes;
};

export const deriveBuilderFromEvent = (event) => {
  if (event != null) {
    try {
      let notes = event.calendarNotes;
      let builder = "";

      if (notes.toLowerCase().includes("lennar")) {
        builder = "Lennar";
      } else if (notes.toLowerCase().includes("richmond")) {
        builder = "Richmond";
      } else if (notes.toLowerCase().includes("meritage")) {
        builder = "Meritage";
      } else if (
        notes.toLowerCase().includes("taylor morrison") ||
        notes.toLowerCase().includes("taymor")
      ) {
        builder = "Taylor Morrison";
      } else if (
        notes.toLowerCase().includes("dreamfinders") ||
        notes.toLowerCase().includes("dream finders")
      ) {
        builder = "Dreamfinders";
      } else if (notes.toLowerCase().includes("tri pointe")) {
        builder = "Tri Pointe";
      } else if (notes.toLowerCase().includes("pulte")) {
        builder = "Pulte";
      } else if (notes.toLowerCase().includes("trumark")) {
        builder = "Trumark";
      } else {
        //  console.log("builder not available", notes);
        builder = "Not Available";
      }

      return builder;
    } catch {
      return "Not Available";
    }
  }
};

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export const deriveBuilder = (home) => {
  if (home != null && home.srCalendarEvents.length > 0) {
    try {
      let notes = home.srCalendarEvents[0].calendarNotes;
      let builder = "";

      if (notes.toLowerCase().includes("lennar")) {
        builder = "Lennar";
      } else if (notes.toLowerCase().includes("richmond")) {
        builder = "Richmond";
      } else if (notes.toLowerCase().includes("meritage")) {
        builder = "Meritage";
      } else if (
        notes.toLowerCase().includes("taylor morrison") ||
        notes.toLowerCase().includes("taymor")
      ) {
        builder = "Taylor Morrison";
      } else if (
        notes.toLowerCase().includes("dreamfinders") ||
        notes.toLowerCase().includes("dream finders")
      ) {
        builder = "Dreamfinders";
      } else if (notes.toLowerCase().includes("tri pointe")) {
        builder = "Tri Pointe";
      } else if (notes.toLowerCase().includes("pulte")) {
        builder = "Pulte";
      } else {
        // console.log("builder not available", notes);
        builder = "Not Available";
      }

      return builder;
    } catch {
      return "Not Available";
    }
  }
};

export const deriveBuilderAbbr = (home) => {
  if (home != null && home.srCalendarEvents.length > 0) {
    try {
      let notes = home.srCalendarEvents[0].calendarNotes;
      let builder = "";

      if (notes.toLowerCase().includes("lennar")) {
        builder = "LEN";
      } else if (notes.toLowerCase().includes("richmond")) {
        builder = "RICH";
      } else if (notes.toLowerCase().includes("meritage")) {
        builder = "MER";
      } else if (
        notes.toLowerCase().includes("taylor morrison") ||
        notes.toLowerCase().includes("taymor")
      ) {
        builder = "TAY";
      } else if (notes.toLowerCase().includes("dreamfinders")) {
        builder = "DF";
      } else if (notes.toLowerCase().includes("tri pointe")) {
        builder = "TRI";
      } else if (notes.toLowerCase().includes("pulte")) {
        builder = "PULTE";
      } else if (notes.toLowerCase().includes("trumark")) {
        builder = "TRU";
      } else {
        builder = "Not Available";
      }

      return builder;
    } catch {
      return "Not Available";
    }
  }
};

export const deriveTech = (home) => {
  let tech = "";
  if (home != null && home.bimRecord?.bimChecklists?.length >= 3) {
    if (home.bimRecord?.bimChecklists[1]?.created_by) {
      tech = home.bimRecord?.bimChecklists[1]?.created_by;
    } else {
      tech = home.bimRecord?.bimChecklists[2]?.created_by;
    }
    tech = tech.slice(0, tech.lastIndexOf("@")).toUpperCase();
  }
  return tech;
};

export const getMonthCount = (range) => {
  const start = moment(range[0]);
  const end = moment(range[1]);

  return end.diff(start, "months") + 1;
};

export const getBuilderIssues = (home) => {
  let issues = [];
  if (home?.bimRecord?.bimIssues.length > 0) {
    home.bimRecord.bimIssues.forEach((issue) => {
      issues.push(issue.description);
    });
  } else {
    issues.push("No listed issues");
  }
  return issues;
};

export const getChecklistErrors = (validation) => {
  let errors = [];
  let checklists = validation.failedPeacockValidatedChecklists;

  if (validation) {
    if (checklists && checklists.length > 0) {
      checklists.forEach((cList) => {
        let errorsToAdd = [];
        errorsToAdd = cList?.validatedList?.issues?.map((issue) => {
          if (issue) return `${cList.listType}: ${issue}`;
        });
        errors = errors.concat(errorsToAdd);
      });
    }
  }
  return errors;
};

export const getMonthCategories = (monthCount) => {
  let monthCategories = [moment().format("MMMM YYYY")];

  for (let i = 1; i < monthCount; i++) {
    monthCategories.unshift(moment().subtract(i, "months").format("MMMM YYYY"));
  }
  return monthCategories;
};
