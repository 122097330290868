export const BACKEND = process.env.REACT_APP_MAIN_API_URL;

/*Auth*/
export const LOGIN = `${BACKEND}auth/login`;
export const REQUEST_RESET = `${BACKEND}auth/reset`;
export const SUBMIT_RESET_PASSWORD = `${BACKEND}auth/resetpassword`;
export const SUBMIT_RESEND_VERIFY = `${BACKEND}auth/resend`;

export const HEARTBEAT = `${BACKEND}auth/heartbeat`;
export const CATEGORIES = `${BACKEND}desigo/categories`;
export const TEST = `${BACKEND}desigo/printReport`;
export const VERIFY = `${BACKEND}auth/verify`;
export const REGISTER = `${BACKEND}auth/register`;

/*Home*/
export const FETCH_HOMES_SEARCH = `${BACKEND}query/search-homes`;
export const FETCH_HOMES = `${BACKEND}query/homes`;
export const UPLOAD_HOMES = `${BACKEND}query/upload-homes`;
export const VALIDATE_HOMES = `${BACKEND}query/validate-homes`;
export const VALIDATE_HOMES_CLA = `${BACKEND}query/validate-homes-cla`;

export const FETCH_PURE_CALENDAR_EVENTS = `${BACKEND}query/pure-calendar-events`;

export const USER_FETCH = `${BACKEND}user/fetch`;
/*Users*/
export const UPDATE_ACCOUNT = `${BACKEND}user/updateAccount`;
export const DELETE_ACCOUNT = `${BACKEND}user/deleteAccount`;

/*Report*/
export const REPORT_FILTERED_HOMES = `${BACKEND}summary/get-many-home-report`;

/*Backend*/
export const EMAIL_ISSUES = `${BACKEND}backend/mail-builders`;
export const UPDATE_BACKUP_CALENDAR_GUI = `${BACKEND}backend/backup-calendar`;

/*Scheduler*/
export const UPDATE_CX_SCHEDULES = `${BACKEND}query/update-cx-schedules`;

/*Loading Mask Page Ids*/
export const INDEX_MASK_ID = 1;
export const DASHBOARD_MASK_ID = 2;
export const DATE_FILTER_MASK_ID = 3;
export const HELPER_MASK_ID = 4;
export const HOMES_MASK_ID = 5;
export const SCHEDULE_MASK_ID = 6;
export const WIDGET_MASK_ID = 7;
