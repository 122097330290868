import { deriveBuilder, groupBy } from "helpers/Service";
import {
  Box,
  Grid,
  Typography,
} from "../../../../node_modules/@mui/material/index";
import moment from "../../../../node_modules/moment/moment";
import DynamicDialog from "components/dialogs/Dialog";
import { getDialogButton } from "helpers/General";
import { useState } from "react";
import SummaryWidget from "components/widgets/summaryWidget";
import SummaryBox from "../SummaryBox";

export default function HomeClosedSummarySquare(props) {
  const { homes, dates } = props;

  const [tableDialog, setTableDialog] = useState(false);
  const [homeDetails, sethomeDetails] = useState([]);
  const [builderClicked, setbuilderClicked] = useState("");
  //homes that are closed are those whose closing date is before now
  //homes that are sucessfull are homes which are in status CX complete
  //otherwise closed with errors
  const resetDialogs = () => {
    setTableDialog(false);
  };

  var closeButton = getDialogButton("Close", resetDialogs);

  let localHomes = homes.map((h) => {
    return { ...h };
  });
  let closedHomes = localHomes.filter((x) => {
    const closing = moment(
      x?.srCalendarEvents[0]?.closingTime,
      "YYYY-MM-DDTHH:mm:ss",
    );

    return closing.isBefore();
  });

  const CXComplete = closedHomes.filter(
    (x) => x.srCalendarEvents[0].calendarColorId === 3,
  );

  closedHomes = closedHomes.map((h) => {
    if (!CXComplete.includes(h)) {
      h.alertMessage = "Home not Commissioned Successfully";
    }
    return h;
  });

  let allhomesByBuilders = groupBy(closedHomes, (x) => deriveBuilder(x));

  const cxCompleteByBuilder = groupBy(CXComplete, (x) => deriveBuilder(x));

  const ClosedWithErrors = closedHomes.filter(
    (x) => x.srCalendarEvents[0].calendarColorId !== 3,
  );
  const closedWithErrorsByBuilder = groupBy(ClosedWithErrors, deriveBuilder);

  const emailReport = (email) => {
    const callAddress = `${
      process.env.REACT_APP_MAIN_API_URL
    }/backend/overall-backlog-remote?startYearMonthDay=${moment(
      dates[0],
    ).format("YYYY,MM,DD")}&endYearMonthDay=${moment().format(
      "YYYY,MM,DD",
    )}&calId=sterlingranchlab.srco@gmail.com&shouldEmailAxiosTechs=false&failedCategory=all&targetBuilder=all&overrideEmail=${email}`;
    fetch(callAddress, {
      method: "GET",
    });
    console.log(
      "back backend api call for backlog report with dates",
      dates,
      "and email",
      email,
      callAddress,
    );
  };

  return (
    <SummaryBox
      color={"#edfbfd"}
      reportFunction={emailReport}
      reportName="backlog"
    >
      <Grid container sx={{ padding: "1em" }}>
        <Grid item xs={12}>
          <Typography variant="h4">{`${
            CXComplete.length + ClosedWithErrors.length
          } Home(s) Closed`}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => {
            sethomeDetails(localHomes);
            setTableDialog(true);
            setbuilderClicked("All Closed Homes");
          }}
        >
          <Typography variant="subtitle4">{`${CXComplete.length}/${
            CXComplete.length + ClosedWithErrors.length
          } Commissioned Successfully`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <br />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">{`Builder Closing Snapshot`}</Typography>
        </Grid>
        {Array.from(allhomesByBuilders).map((row) => {
          return (
            <Grid
              item
              container
              xs={12}
              key={row[0]}
              onClick={() => {
                sethomeDetails(row[1]);
                setTableDialog(true);
                setbuilderClicked(row[0]);
              }}
              sx={{ cursor: "pointer", textDecoration: "underline" }}
            >
              <Grid item xs={6}>
                {`${row[0]}: `}
              </Grid>
              <Grid item xs={3}>
                {`${
                  cxCompleteByBuilder.get(row[0])
                    ? cxCompleteByBuilder.get(row[0]).length
                    : 0
                } / ${row[1].length}`}
              </Grid>

              <Grid item xs={3}>
                {`${(
                  ((cxCompleteByBuilder.get(row[0])
                    ? cxCompleteByBuilder.get(row[0]).length
                    : 0) /
                    row[1].length) *
                  100
                ).toFixed(0)}%`}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <DynamicDialog
        maxWidth="lg"
        fullWidth
        buttons={[closeButton]}
        open={tableDialog}
        title={builderClicked}
        //children={<HomeDetailsCard {...homeDetails} />}
        children={<SummaryWidget homes={homeDetails} />}
      />
    </SummaryBox>
  );
}
