import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";

// scroll bar
import "simplebar/src/simplebar.css";

// third-party
import { Provider as ReduxProvider } from "react-redux";

// apex-chart
import "assets/third-party/apex-chart.css";

import theme from "./assets/theme";

// project import
import App from "./App";
import { store } from "store";
import { takeIfExists } from "./utils/localStorage";
import { INDEX_MASK_ID, USER_FETCH } from "./constants/api";
import { header } from "./utils/request";
import { headerWithAuth, loggedIn, updateUserData } from "./utils/auth";
import { update } from "store/reducers/connection";
import { updateLoading } from "store/reducers/mask";
import { updateHomes } from "store/reducers/homes";
import { updateValidations } from "store/reducers/validation";
import { validateHomes } from "helpers/SrApiCalls";
import { LicenseInfo } from "@mui/x-license-pro";
import { receiveUser } from "store/reducers/user";
LicenseInfo.setLicenseKey(
  "515e78ee13569611a925becc0d564da0Tz03MzM4MSxFPTE3MjQ1MTQ2MzAwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y",
);

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

/*  When the app loads up, try to login.  
    We can have stored creds in cookie.  Saved after successful login.  Or send in blank creds.  
    Will use values stored in appSettings.json on server side.
*/
const Init = (store) => {
  var data = { Id: takeIfExists("id") };
  store.dispatch(updateLoading({ loading: true, fromId: INDEX_MASK_ID  }));

  if (loggedIn()) {
    fetch(USER_FETCH, {
      method: "POST",
      headers: headerWithAuth(),
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status == 200) {
          //Ok may have json data may not.

          return response
            .json() // return the result of the inner promise, which is an error
            .then((json) => {
              store.dispatch(receiveUser(json));
              store.dispatch(updateLoading({ loading: false, fromId: INDEX_MASK_ID  }));
              store.dispatch(
                update({
                  bimAuthResponse: json.bimAuthResponse,
                  copperAuthResponse: json.copperAuthResponse,
                  freshdeskAuthResponse: json.freshdeskAuthResponse,
                  googleAuthResponse: json.googleAuthResponse,
                }),
              );

              store.dispatch(updateHomes({ homes: json.allHomes }));

              fetchValidations(json.allHomes)
                .then((valids) => {
                  store.dispatch(
                    updateValidations({
                      validations:
                        valids != null ? valids : ["Validations returned null"],
                    }),
                  );
                })
                .catch((error) => {
                  console.log("Error:", error);
                  store.dispatch(
                    updateValidations({ validations: ["Validations error"] }),
                  );
                });

              return null;
            })
            .catch((error) => {
              console.error("Error:", error);
              store.dispatch(updateLoading({ loading: false, fromId: INDEX_MASK_ID  }));
              store.dispatch(
                update({
                  bimAuthResponse: null,
                  copperAuthResponse: null,
                  freshdeskAuthResponse: null,
                  googleAuthResponse: null,
                }),
              );
            });
        } //Some error before uploading the files
        else {
          store.dispatch(updateLoading({ loading: false, fromId: INDEX_MASK_ID  }));
          store.dispatch(
            update({
              bimAuthResponse: null,
              copperAuthResponse: null,
              freshdeskAuthResponse: null,
              googleAuthResponse: null,
            }),
          );
          return null;
        }
      })
      .catch((error) => {
        store.dispatch(updateLoading({ loading: false, fromId: INDEX_MASK_ID  }));
        store.dispatch(
          update({
            bimAuthResponse: null,
            copperAuthResponse: null,
            freshdeskAuthResponse: null,
            googleAuthResponse: null,
          }),
        );
        console.error("Error:", error);
      });
  } else {
    store.dispatch(updateLoading({ loading: false, fromId: INDEX_MASK_ID  }));
  }

  const fetchValidations = async (homes) => {
    if (homes?.length > 0) {
      let validatedHomes = await validateHomes(homes);
      // console.log("Init Validations: ", validatedHomes);
      return validatedHomes;
    } else {
      console.log("No homes to validate");
      return null;
    }
  };
};

Init(store);

ReactDOM.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter basename="/">
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </ReduxProvider>
  </StrictMode>,
  document.getElementById("root"),
);
