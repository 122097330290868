import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import moment from "moment";
import {
  getGoogleCalendarColor,
  deriveBuilder,
  getGoogleCalendarColorFromEvents,
  deriveBuilderFromEvent,
} from "helpers/Service";
import { alpha, styled, useTheme } from "@mui/material/styles";
import EmailIcon from "@mui/icons-material/Email";
import {
  Button,
  Grid,
  Tooltip,
  Typography,
} from "../../../../node_modules/@mui/material/index";
import DynamicDialog from "components/dialogs/Dialog";
import {
  Check,
  CheckCircleOutline,
  Close,
  IosShare,
  PriorityHigh,
} from "../../../../node_modules/@mui/icons-material/index";
import { getDialogButton } from "helpers/General";
import { validateBuilderChecklists } from "helpers/ValidateBuilderChecklistDetails";

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

const dateTypeRegex = /(\d{1,4})([\/-])(\d{1,2})\2(\d{1,4})/;

export default function BimChecklistTable(props) {
  const theme = useTheme();

  const { bimRecord } = props;

  const [detailsDialog, setdetailsDialog] = useState(false);
  const [dialogClicked, setdialogClicked] = useState("Detail");
  const [checkDetail, setcheckDetail] = useState([]);
  const [showImage, setshowImage] = useState(false);
  const [showImageSource, setshowImageSource] = useState("");
  const resetDialogs = () => {
    setdetailsDialog(false);
    setshowImage(false);
    setdialogClicked("Detail");
    setcheckDetail([]);
  };
  const closeImage = () => {
    setshowImage(false);
  };
  var closeImageButton = getDialogButton("Close", closeImage);
  var closeButton = getDialogButton("Close", resetDialogs);
  const renderCheckDetails = (params) => {
    if (
      params.row.bimChecklistDetail?.builderIssues &&
      params.row.bimChecklistDetail?.builderIssues.length > 0
    ) {
      //  console.log("builder isues");
      return (
        <Box>
          <Tooltip title="Checklist Details Available">
            <IosShare
              style={{
                color: "blue",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            />
          </Tooltip>
          <Tooltip title="Builder Issues Present" sx={{ position: "absolute" }}>
            <PriorityHigh
              style={{
                color: "red",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            />
          </Tooltip>
        </Box>
      );
    }
    if (params.formattedValue) {
      return (
        <Tooltip title="Checklist Details Available">
          <IosShare
            style={{
              color: "blue",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          />
        </Tooltip>
      );
    }
  };

  const renderAttachments = (params) => {
    if (params.row.attachments.length > 0) {
      return (
        <div>
          {params.row.attachments.map((im) => (
            <div style={{ display: "grid" }}>
              <img
                key={im.public_url}
                align="center"
                src={im.public_url}
                height="250"
                alt="Attachment image"
                onClick={() => {
                  setshowImageSource(im.public_url);
                  setshowImage(true);
                }}
              ></img>
              created at: {im.created_at} updated at: {im.updated_at}
            </div>
          ))}
        </div>
      );
    }
    return params.row.attachments.join();
  };

  const renderValues = (params) => {
    if (checkDetail?.builderIssues?.indexOf(params.row.question_text) > -1) {
      return (
        <Box>
          {params?.row.custom_field_values.value}
          <Tooltip title="Builder Issues Present">
            <PriorityHigh style={{ color: "red" }} />
          </Tooltip>
        </Box>
      );
    } else {
      return params?.row.custom_field_values?.value
        ? params?.row.custom_field_values?.value
        : "";
    }
  };

  const checklistItemColumns = [
    { field: "question_text", headerName: "Question Text", width: 450 },
    {
      field: "custom_field_values",
      headerName: "Answer",
      align: "center",
      width: 150,
      renderCell: (params) => renderValues(params),
    },

    /*{
      field: "details",
      headerName: "Details",
      width: 250,
    },*/
    {
      field: "attachments",
      headerName: "Attachments",
      flex: 1,
      renderCell: (params) => renderAttachments(params),
    },
  ];

  const checklistColumns = [
    { field: "name", headerName: "Checklist Name", width: 250 },
    { field: "created_by", headerName: "Technician", width: 150 },

    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    { field: "updated_at", headerName: "Last Update", width: 150 },
    {
      field: "bimChecklistDetail",
      headerName: "Details Available",
      align: "center",
      width: 150,
      renderCell: (params) => renderCheckDetails(params),
    },
  ];

  const issueColumns = [
    { field: "description", headerName: "Description", width: 250 },
    { field: "created_by", headerName: "Technician", width: 150 },

    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    { field: "updated_at", headerName: "Last Update", width: 150 },
  ];

  const checklistRows = [...bimRecord.bimChecklists.filter(Boolean)];
  let builderIssueChecklist = checklistRows.filter(
    (x) =>
      x.name.replaceAll(" ", "").toLowerCase() ===
      "cabbuilderreadinesschecklistgen3",
  )[0];
  if (builderIssueChecklist) {
    var builderIssues = validateBuilderChecklists(checklistRows);
    //console.log("issue here", builderIssues);

    builderIssueChecklist.bimChecklistDetail["builderIssues"] = builderIssues;
  }

  const issueRows = [...bimRecord.bimIssues.filter(Boolean)];
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          //minHeight: 200,

          height: props.height
            ? props.height
            : Math.min(100 + checklistRows.length * 75, 400),
        }}
      >
        <Typography variant="h5" component="div">
          {"BIM Checklists "}
        </Typography>
        <StripedDataGrid
          getRowHeight={() => "auto"}
          columns={checklistColumns}
          getRowId={(row) => row.completed_checklist_id}
          rows={checklistRows}
          onRowClick={(params, event) => {
            if (event.target.type === "button") return; //dont trigger the whole row popup if we click a nested button

            if (params.row.bimChecklistDetail) {
              setdetailsDialog(true);
              setdialogClicked(`${params.row.name} Details`);
              setcheckDetail(params.row.bimChecklistDetail);
            }
          }}
          // rowThreshold={0}
          //pageSize={pageSize}
          //  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          //  rowsPerPageOptions={[5, 10, 20, 50, 200]}
          //  pagination
          initialState={{
            sorting: {
              sortModel: [{ field: "updated_at", sort: "asc" }],
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          sx={{
            [`& .${gridClasses.row}`]: {
              "&:hover, &.Mui-hovered": {
                backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
                "@media (hover: none)": {
                  backgroundColor: "transparent",
                },
              },
            },
          }}
        />
      </Box>

      <Box
        sx={{
          width: "100%",

          height: props.height
            ? props.height
            : Math.min(100 + issueRows.length * 75, 400),
        }}
      >
        <Typography variant="h5" component="div">
          {"BIM Issues "}
        </Typography>
        <StripedDataGrid
          getRowHeight={() => "auto"}
          columns={issueColumns}
          getRowId={(row) => row.issue_id}
          rows={issueRows}
          // rowThreshold={0}
          //pageSize={pageSize}
          //  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          //  rowsPerPageOptions={[5, 10, 20, 50, 200]}
          //  pagination
          initialState={{
            sorting: {
              sortModel: [{ field: "updated_at", sort: "asc" }],
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          sx={{
            [`& .${gridClasses.row}`]: {
              "&:hover, &.Mui-hovered": {
                backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
                "@media (hover: none)": {
                  backgroundColor: "transparent",
                },
              },
            },
          }}
        />
      </Box>
      <DynamicDialog
        maxWidth="xxlg"
        fullWidth
        buttons={[closeImageButton]}
        open={showImage}
        title={"Notes"}
        children={<img src={showImageSource} />}
      />
      <DynamicDialog
        maxWidth="lg"
        fullWidth
        buttons={[closeButton]}
        open={detailsDialog}
        title={dialogClicked}
        //children={<HomeDetailsCard {...homeDetails} />}
        children={
          checkDetail &&
          checkDetail.checklist_items &&
          checkDetail.checklist_items.length > 0 && (
            <Box
              key={`${checkDetail.name}_${checkDetail.completed_checklist_id}`}
              sx={{
                height: Math.min(
                  100 + checkDetail?.checklist_items?.length * 200,
                  700,
                ),
              }}
            >
              <StripedDataGrid
                getRowHeight={() => "auto"}
                columns={checklistItemColumns}
                getRowId={(row) => row.completed_checklist_item_id}
                rows={checkDetail?.checklist_items}
                // rowThreshold={0}
                //pageSize={pageSize}
                //  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                //  rowsPerPageOptions={[5, 10, 20, 50, 200]}
                //  pagination

                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
                sx={{
                  [`& .${gridClasses.row}`]: {
                    "&:hover, &.Mui-hovered": {
                      backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY,
                      ),
                      "@media (hover: none)": {
                        backgroundColor: "transparent",
                      },
                    },
                  },
                }}
              />
            </Box>
          )
        }
      />
    </Box>
  );
}
